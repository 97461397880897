import React from "react";
import BackToTop from "../components/BackToTop";
import Forms from "../components/Contact/Forms";
import HeroNews from "../components/Contact/HeroNews";
import FooterHomeThree from "../components/Home/FooterHomeThree";
import HeaderHomeThree from "../components/Home/HeaderHomeThree";
import Drawer from "../components/Mobile/Drawer";
import useToggle from "../Hooks/useToggle";
import MetaData from "../Hooks/Meta-tag";

function Contact() {
  const [drawer, drawerAction] = useToggle(false);
  return (
    <>
      <MetaData
        content={"Battery World"}
        title={"PREMIUM INVERTER, BATTERY & SOLAR SYSTEM SALES & SERVICE"}
        description={
          "Welcome to Battery World, your trusted source for batteries, solar systems, and home inverters in Malappuram/Chattiparamba. Explore our wide range of products and services to power your life efficiently and affordably"
        }
        canonicalLink="https://batteryworldmpm.in/contact"
      />
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HeaderHomeThree action={drawerAction.toggle} />

      <HeroNews
        title="Contact us"
        breadcrumb={[
          { link: "/", title: "home" },
          { link: "/news", title: "contact" },
        ]}
      />
      <Forms />
      <FooterHomeThree />
      <BackToTop />
    </>
  );
}

export default Contact;
