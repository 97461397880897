import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <>
      <ul>
        <li>
          <Link to="/" title="Home">
            Home
          </Link>
        </li>
        <li>
          <Link title="Service" to="/service">
            Service
          </Link>
        </li>
        <li>
          <Link title="About" to="/about-us">
            About
          </Link>
        </li>
        <li>
          <Link title="Contact" to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </>
  );
}

export default Navigation;
