import React from "react";


function Forms() {
  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact--info-area">
                <h3>Get in touch</h3>
                <p>
                  Looking for help? Fill the form and start a new adventure.
                </p>
                <div className="single-info">
                  <h5>Shop</h5>
                  <p>
                    <i className="fal fa-home"></i>
                    Chattipparamba, Kottakkal Road,
                    <br /> Kerala 676504
                  </p>
                </div>
                <div className="single-info">
                  <h5>Phone</h5>
                  <p>
                    <i className="fal fa-phone"></i>
                    (+91) 9495566067
                    <br />
                    (+91) 6235566067
                  </p>
                </div>
                <div className="single-info">
                  <h5>Support</h5>
                  <p>
                    <i className="fal fa-envelope"></i>
                    batteryworldmpm@gmail.com
                    <br />
                    info.batteryworldmpm@gmail.com
                  </p>
                </div>
                <div className="ab-social">
                  <h5>Follow Us</h5>
                  <a
                    className="fac"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/profile.php?id=100063748002698&mibextid=LQQJ4d"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  {/* <a className="twi" href="#!">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="you" href="#!">
                    <i className="fab fa-youtube"></i>
                  </a> */}
                  <a
                    className="lin"
                    rel="noreferrer"
                    target="_blank"
                    href="https://wa.me/9495566067"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="contact-form">
                <h4>Let’s Connect</h4>
                <p>Integer at lorem eget diam facilisis lacinia ac id massa.</p>
                <form action="#" method="post" className="row">
                  <div className="col-md-6">
                    <input type="text" name="f-name" placeholder="First Name" />
                  </div>
                  <div className="col-md-6">
                    <input type="text" name="l-name" placeholder="Last Name" />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="col-md-12">
                    <input type="text" name="suject" placeholder="Subject" />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      name="message"
                      placeholder="How can we help?"
                    ></textarea>
                  </div>
                  <div className="col-md-6">
                    <div className="condition-check">
                      <input id="terms-conditions" type="checkbox" />
                      <label htmlFor="terms-conditions">
                        I agree to the Terms & Conditions
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 text-right">
                    <input type="submit" name="submit" value="Send Message" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bisylms-map">
        <iframe
          title="map"
          src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=Battery World, Chattipparamba - Kadampuzha Road, Kottakkal, Kerala&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          // src="https://www.google.com/maps/place/Battery+World/@10.9992172,76.0883785,15z/data=!4m5!3m4!1s0x0:0x9162a269124d1930!8m2!3d10.9992172!4d76.0883785"
          // src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;coord=10.9992172,76.0883785&amp;q=1%Battery%World%2C%20Chattiparamba%2C%20+(Battery%20World)&amp;ie=UTF8&amp;t=p&amp;z=16&amp;iwloc=B&amp;output=embed"
        ></iframe>
        {/* <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=Battery World, Chattipparamba - Kadampuzha Road, Kottakkal, Kerala&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}
      </div>
    </>
  );
}

export default Forms;
