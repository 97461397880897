import React from "react";

function ServicesAbout() {
  return (
    <>
      <section className="appie-services-2-area pt-90 pb-55" id="service">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6 col-md-8">
              <div className="appie-section-title">
                <h3 className="appie-title">We’re driven by our values</h3>
                {/* <p>The app provides design and digital marketing. </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i className="fal fa-truck"></i>
                </div>
                <h4 className="title">DoorStep Delivery</h4>
                <p>Give us a call, and we'll deliver right to your door</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="icon">
                  <i className="fal fa-car"></i>
                </div>
                <h4 className="title">Automotive battery</h4>
                <p>We will deliver and install the automotive battery</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="icon">
                  <i className="fal fa-home"></i>
                </div>
                <h4 className="title">Inverter For Home</h4>
                {/* <p>He lost his bottle loo don't get shirty with me ruddy.</p> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i className="fal fa-solar-panel"></i>
                </div>
                <h4 className="title">Solar</h4>
                <p>Adding Green Energy to your Life.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="icon">
                  <i className="fal fa-retweet"></i>
                </div>
                <h4 className="title">Lifetime assistance</h4>
                <p>
                  ou can reach out to us at any time, and we'll be available.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 appie-single-service-about item-6 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="icon">
                  <i className="fal fa-bell"></i>
                </div>
                <h4 className="title">Excellent Customer Service</h4>
                <p>
                  We'll value consumers' time, adopt a positive attitude, and
                  offer informed, useful information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesAbout;
