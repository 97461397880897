import React from "react";

function SEOData() {
  return (
    <div style={{ width: "80%", margin: "20px auto", textAlign: "justify" }}>
      <p style={{ fontSize: "12px" }}>
        Welcome to Battery World, your one-stop destination for all your
        battery, solar system, and home inverter needs in
        Malappuram/Chattiparamba. With a wide range of products and services, we
        are committed to providing reliable and affordable solutions to power
        your life. At Battery World, we understand the importance of quality and
        reliability when it comes to batteries and power systems. That's why we
        offer a diverse selection of batteries for various applications,
        including automotive, industrial, and renewable energy. Whether you need
        a battery for your car, truck, boat, or solar power system, we have you
        covered. In addition to batteries, we also specialize in solar systems
        and home inverters. Our team of experts can help you design and install
        a custom solar power system for your home or business, helping you save
        money on your energy bills while reducing your carbon footprint.
        Customer satisfaction is our top priority, which is why we strive to
        provide exceptional service and support to all our customers. Our
        knowledgeable staff is always on hand to answer any questions you may
        have and help you find the perfect solution for your
      </p>
      <p style={{ fontSize: "12px" }}>
        Battery World is proud to offer a comprehensive range of battery-related
        services, including battery testing, charging, and recycling. Our
        state-of-the-art testing equipment ensures that your battery is in
        optimal condition, while our charging services help extend its lifespan.
        And when it's time to replace your old battery, we'll recycle it in an
        environmentally responsible manner. We also offer a variety of
        accessories and peripherals to complement your battery, solar system, or
        home inverter. From battery chargers and inverters to cables and
        connectors, we have everything you need to ensure that your power system
        is running smoothly. At Battery World, we are committed to providing our
        customers with the highest level of service and support. Whether you're
        a homeowner looking to install a solar power system or a business owner
        in need of reliable backup power, we have the expertise and resources to
        meet your needs. So why choose Battery World? With our extensive
        selection of products and services, knowledgeable staff, and commitment
        to customer satisfaction, we are your go-to destination for all your
        battery, solar system, and home inverter needs. Visit us today and
        experience the Battery World difference!
      </p>
    </div>
  );
}

export const SEODataTwo = () => {
  return (
    <>
      <div
        style={{
          width: "80%",
          margin: "20px auto",
          textAlign: "justify",
          marginTop: "50px",
        }}
      >
        <p style={{ fontSize: "10px" }}>
          Battery World is proud to offer a comprehensive range of
          battery-related services, including battery testing, charging, and
          recycling. Our state-of-the-art testing equipment ensures that your
          battery is in optimal condition, while our charging services help
          extend its lifespan. And when it's time to replace your old battery,
          we'll recycle it in an environmentally responsible manner. We also
          offer a variety of accessories and peripherals to complement your
          battery, solar system, or home inverter. From battery chargers and
          inverters to cables and connectors, we have everything you need to
          ensure that your power system is running smoothly. At Battery World,
          we are committed to providing our customers with the highest level of
          service and support. Whether you're a homeowner looking to install a
          solar power system or a business owner in need of reliable backup
          power, we have the expertise and resources to meet your needs. So why
          choose Battery World? With our extensive selection of products and
          services, knowledgeable staff, and commitment to customer
          satisfaction, we are your go-to destination for all your battery,
          solar system, and home inverter needs. Visit us today and experience
          the Battery World difference!
        </p>
      </div>
      <div style={{ width: "80%", margin: "20px auto", textAlign: "justify" }}>
        <p style={{ fontSize: "10px" }}>
          Battery World is a leading provider of battery, solar system, and home
          inverter solutions in Malappuram/Chattiparamba. With over 10 years of
          experience in the industry, we have established ourselves as a trusted
          name for high-quality products and exceptional service. Our team of
          experts is dedicated to helping you find the right solution for your
          needs. Whether you're looking for a battery for your car, truck, boat,
          or solar power system, we have a wide range of options to choose from.
          We also offer custom solutions for commercial and industrial
          applications, ensuring that your power needs are met. In addition to
          our extensive product range, we also offer a variety of services to
          help you get the most out of your battery, solar system, or home
          inverter. Our installation and maintenance services ensure that your
          system is installed correctly and maintained to the highest standards.
          We also offer repair services for all types of batteries, inverters,
          and solar panels. At Battery World, we are committed to providing our
          customers with the best possible experience. Our friendly and
          knowledgeable staff is always on hand to answer any questions you may
          have and help you find the right solution for your needs. With our
          dedication to customer satisfaction and our extensive range of
          products and services, we are your one-stop destination for all your
          battery, solar system, and home inverter needs. Visit us today and
          experience the Battery World difference!
        </p>
      </div>
      <div style={{ width: "80%", margin: "20px auto", textAlign: "justify" }}>
        <p style={{ fontSize: "10px" }}>
          Inverter Sales & Service: We offer a wide selection of inverters from
          leading brands, including Exide, Amaron, and Indecs. Whether you need
          a grid-tied inverter, off-grid inverter, or hybrid inverter, we have
          the right solution for you. Our team of experts can help you choose
          the right inverter for your needs and provide professional
          installation and maintenance services to ensure optimal performance.
          Battery Sales & Service: We stock a comprehensive range of batteries
          for various applications, including automotive, industrial, and
          renewable energy. Our batteries are sourced from top manufacturers,
          ensuring quality and reliability. Whether you need a battery for your
          car, truck, boat, or solar power system, we have you covered. We also
          offer battery testing, charging, and recycling services to help you
          get the most out of your battery. Solar System Sales & Service: We
          specialize in designing and installing custom solar power systems for
          homes and businesses. Our team of experts will work with you to assess
          your energy needs and design a system that maximizes your energy
          savings. We offer a variety of solar panels, inverters, and mounting
          systems to suit your specific requirements. We also provide ongoing
          maintenance and support to ensure that your solar system continues to
          perform at its best. Why Choose Battery World? Extensive range of
          products and services Quality products from top manufacturers
          Professional installation and maintenance services Knowledgeable and
          friendly staff Commitment to customer satisfaction Whether you're
          looking to purchase a new inverter, battery, or solar system, or need
          professional installation and maintenance services, Battery World is
          your one-stop destination. Visit us today and experience the Battery
          World difference!
        </p>
        <p style={{ fontSize: "10px" }}>
          At Battery World, we understand the importance of reliable power
          solutions for your home, business, or recreational needs. Our team of
          experts is dedicated to providing you with personalized service and
          support, ensuring that you find the right solution for your specific
          requirements. Whether you're looking for a high-performance battery, a
          state-of-the-art inverter, or a custom solar power system, we have the
          expertise and resources to meet your needs. With our commitment to
          quality, reliability, and customer satisfaction, you can trust Battery
          World to power your life. Visit us today and discover why we're the
          preferred choice for inverter, battery, and solar system sales and
          service.
        </p>
      </div>
      <div style={{ width: "80%", margin: "20px auto", textAlign: "justify" }}>
        <p style={{ fontSize: "10px" }}>
          Certainly! Here's some more content you can include on your website
          about inverter, battery, and solar system sales and service: Inverter
          Sales & Service: Battery World offers a wide range of inverters to
          suit your needs, from grid-tied inverters for residential and
          commercial applications to off-grid and hybrid inverters for remote
          locations or backup power. Our expert team can help you select the
          right inverter for your needs and provide professional installation
          and maintenance services. Battery Sales & Service: We offer a variety
          of batteries, including lead-acid, lithium-ion, and nickel-cadmium
          batteries, for a range of applications such as automotive, marine, and
          renewable energy. Our team can help you choose the right battery for
          your needs and provide services such as battery testing, charging, and
          recycling. Solar System Sales & Service: Battery World specializes in
          designing and installing custom solar power systems for homes and
          businesses. We offer a range of solar panels, inverters, and mounting
          systems to suit your specific requirements. Our team can also provide
          ongoing maintenance and support to ensure your solar system continues
          to perform at its best.
        </p>
      </div>
    </>
  );
};

export default SEOData;
