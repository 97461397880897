import React from "react";
import HeroAbout from "../components/AboutUs/HeroAbout";
import ServicesAbout from "../components/AboutUs/ServicesAbout";
import BackToTop from "../components/BackToTop";
import SponserHomeTwo from "../components/Home/SponserHomeTwo";
import Drawer from "../components/Mobile/Drawer";
import SignupHomeEight from "../components/Home/SignupHomeEight";
import useToggle from "../Hooks/useToggle";
import HeaderHomeThree from "../components/Home/HeaderHomeThree";
import FooterHomeThree from "../components/Home/FooterHomeThree";
import MetaData from "../Hooks/Meta-tag";

function AboutUs() {
  const [drawer, drawerAction] = useToggle(false);
  return (
    <>
      <MetaData
        content={"About Us"}
        title={"PREMIUM INVERTER, BATTERY & SOLAR SYSTEM SALES & SERVICE"}
        description={
          "Welcome to Battery World, your trusted source for batteries, solar systems, and home inverters in Malappuram/Chattiparamba. Explore our wide range of products and services to power your life efficiently and affordably"
        }
        canonicalLink="https://batteryworldmpm.in/about-us"
      />
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HeaderHomeThree action={drawerAction.toggle} />
      <HeroAbout />
      <ServicesAbout />
      <SponserHomeTwo />
      <SignupHomeEight />
      <FooterHomeThree className="appie-footer-about-area" />
      <BackToTop />
    </>
  );
}

export default AboutUs;
