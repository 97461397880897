import React from "react";
import useCloudinary from "../../Hooks/UseCloudinaryImage";
import { solar1, solar2 } from "../util/CloudData";
import { FaMobile } from "react-icons/fa";
import { BsFillPhoneVibrateFill } from "react-icons/bs";

function DownloadHomeThree({ className }) {
  return (
    <>
      <section
        className={`appie-download-3-area pt-100 ${className || ""}`}
        id="download"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Solar System</h3>
                {/* <p >STILL LIVING UNDER THE BURDEN OF HIGH ELECTRICITY BILL?<br />
                  SAVE ON BILLS BY CHOOSEING SOLAR</p> */}
                <p>
                  Still living under the burden of high electricity bills?
                  <br /> Save on bills by choosing solar
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="content">
                  <h4 className="title">On Grid Solar System</h4>
                  <p>
                    An on-grid or grid-tied solar system is a system that works
                    along with the grid. This means that any excess or
                    deficiency of power can be fed to the grid through net
                    metering. Many residential users are opting for an On-grid
                    solar system as they get a chance to enjoy credit for the
                    excess power their system produces and save on their
                    electricity bills. You will always have power either from
                    the solar system or from the grid. They do not have
                    batteries.
                  </p>
                  <a href="tel:9495566067" className="main-btn">
                    {/* <i className="fab fa-google-play" /> */}
                    <FaMobile className="mx-1" style={{ fontSize: "16px" }} />
                    Contact Now
                  </a>
                </div>
                <div className="thumb text-center p-4">
                  <img
                    src={useCloudinary(solar1, "")}
                    alt=""
                    width={430}
                    height={280}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="content">
                  <h4 className="title"> Off-Grid Solar System</h4>
                  <p>
                    An off-grid solar energy system is not connected to the utility grid, whereas an on-grid (aka grid-tied) solar energy system is connected to the utility grid. Whether off-grid or on-grid system will determine your access to electricity, what equipment is needed for excess production, what happens when the grid goes down, and how you’re billed for electricity.
                  </p>
                  <a className="main-btn main-btn-2" href="tel:9495566067">
                    {/* <i className="fab fa-apple" /> */}
                    <BsFillPhoneVibrateFill
                      className="mx-1 shake"
                      style={{ fontSize: "16px" }}
                    />
                    Get in touch with us
                  </a>
                </div>
                <div className="thumb text-center p-4">
                  <img
                    src={useCloudinary(solar2, "")}
                    alt=""
                    width={430}
                    height={280}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DownloadHomeThree;
