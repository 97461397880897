import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import shape12 from "../../assets/images/shape/shape-12.png";
import shape13 from "../../assets/images/shape/shape-13.png";
import shape14 from "../../assets/images/shape/shape-14.png";
import shape15 from "../../assets/images/shape/shape-15.png";
import useCloudinary from "../../Hooks/UseCloudinaryImage";
import {
  amaron_p1,
  amaron_p2,
  exide_p1,
  exide_p2,
  indecs1,
  indecs2,
  indecs3,
  indecs4,
  nduron_p1,
} from "../util/CloudData";

function ShowCaseHomeThree() {
  const sliderRef = useRef();
  // const sliderNext = () => {
  //     sliderRef.current.slickNext();
  // };
  // const sliderPrev = () => {
  //     sliderRef.current.slickPrev();
  // };
  const settings = {
    autoplay: false,
    arrows: false,
    dots: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="appie-showcase-area">
        <SimpleReactLightbox>
          <SRLWrapper>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="appie-section-title text-center">
                    <h3 className="appie-title">
                      Top Trending Products & Services
                    </h3>
                    <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                      Stay ahead of the curve with our selection of top trending
                      products and services. From the latest inverter technology
                      to cutting-edge solar panels, Battery World has everything
                      you need to power your life. Our expert team is always on
                      the lookout for the latest innovations in the industry, so
                      you can trust that you're getting the best products and
                      services available. Check out our top trending products
                      and services today and see what's new at Battery World!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row appie-showcase-slider">
                <div className="col-lg-12">
                  <Slider ref={sliderRef} {...settings}>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#!"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              indecs1,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#!"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              indecs2,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#!"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              indecs3,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#!"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              indecs4,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#!"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              indecs1,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link to={"#!"} className="appie-image-popup">
                          <img
                            src={useCloudinary(
                              indecs2,
                              "e_improve,w_300,h_450"
                            )}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link to={"#!"} className="appie-image-popup">
                          <img
                            src={useCloudinary(
                              indecs3,
                              "e_improve,w_300,h_450"
                            )}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link to={"#!"} className="appie-image-popup">
                          <img
                            src={useCloudinary(
                              indecs4,
                              "e_improve,w_300,h_450"
                            )}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link to={"#!"} className="appie-image-popup">
                          <img
                            src={useCloudinary(
                              nduron_p1,
                              "e_improve,w_300,h_450"
                            )}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div to={"#"} className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#!"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              amaron_p2,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              exide_p2,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              amaron_p1,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="appie-showcase-item mt-30">
                        <Link
                          title="malappuram battery world"
                          to={"#"}
                          className="appie-image-popup"
                        >
                          <img
                            width="100%"
                            height="100%"
                            src={useCloudinary(
                              exide_p1,
                              "e_improve,w_300,h_450"
                            )}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <div className="showcase-shape-1">
              <img height={30} width={30} src={shape14} alt="" />
            </div>
            <div className="showcase-shape-2">
              <img height={30} width={30} src={shape13} alt="" />
            </div>
            <div className="showcase-shape-3">
              <img height={30} width={30} src={shape12} alt="" />
            </div>
            <div className="showcase-shape-4">
              <img height={30} width={30} src={shape15} alt="" />
            </div>
          </SRLWrapper>
        </SimpleReactLightbox>
      </section>
    </>
  );
}

export default ShowCaseHomeThree;
