import React, { useEffect } from "react";
import BackToTop from "../components/BackToTop";
import FooterHomeThree from "../components/Home/FooterHomeThree";
import HeaderHomeThree from "../components/Home/HeaderHomeThree";
import ProjectHomeOne from "../components/Home/ProjectHomeOne";
import Drawer from "../components/Mobile/Drawer";
import DetailsService from "../components/Service/DetailsService";
import HeroService from "../components/Service/HeroService";
import useToggle from "../Hooks/useToggle";
import StickyMenu from "../lib/StickyMenu";
import MetaData from "../Hooks/Meta-tag";

function Service() {
  useEffect(() => {
    StickyMenu();
  });
  const [drawer, drawerAction] = useToggle(false);
  return (
    <>
      <MetaData
        content={"Battery World"}
        title={"PREMIUM INVERTER, BATTERY & SOLAR SYSTEM SALES & SERVICE"}
        description={
          "Welcome to Battery World, your trusted source for batteries, solar systems, and home inverters in Malappuram/Chattiparamba. Explore our wide range of products and services to power your life efficiently and affordably"
        }
        canonicalLink="https://batteryworldmpm.in/service"
      />
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HeaderHomeThree action={drawerAction.toggle} />
      <HeroService />
      <DetailsService />
      <ProjectHomeOne />
      <FooterHomeThree />
      <BackToTop />
    </>
  );
}

export default Service;
