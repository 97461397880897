import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../../assets/images/index";

function FooterHomeThree({ className }) {
  return (
    <>
      <section className={`appie-footer-area appie-footer-3-area ${className}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget footer-about-widget-3">
                <div className="logo">
                  <Link title="battery world mpm" to="#">
                    <img src={logo} alt="" height={80} width={100} />
                  </Link>
                </div>
                <p>INVERTER, BATTERY & SOLAR SYSTEM SALES & SERVICE</p>

                <div className="social mt-30">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/profile.php?id=100063748002698&mibextid=LQQJ4d"
                      >
                        <i className="fab fa-facebook-f" />
                        <span
                          style={{ color: "transparent", visibility: "hidden" }}
                        >
                          Call me on whatsapp
                        </span>
                      </a>
                    </li>
                    {/* <li>
                      <Link title='battery world mpm' to="#">
                        <i className="fab fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link title='battery world mpm' to="#">
                        <i className="fab fa-youtube" />
                      </Link>
                    </li> */}
                    <li>
                      <a
                        href="https://wa.me/9495566067"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp" />
                        <span
                          style={{ color: "transparent", visibility: "hidden" }}
                        >
                          Call me on whatsapp
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-6 sm:mt-5">
              <div className="footer-navigation footer-navigation-3">
                <h4 className="title">Company</h4>
                <ul>
                  <li>
                    <Link title="battery world mpm" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link title="battery world mpm" to="/Service">
                      Service
                    </Link>
                  </li>

                  <li>
                    <Link title="battery world mpm" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 sm:mt-5">
              <div className="footer-navigation footer-navigation-3">
                <h4 className="title">Support</h4>
                <ul>
                  <li>
                    <Link title="battery world mpm" to="#">
                      Faqs
                    </Link>
                  </li>
                  <li>
                    <Link title="battery world mpm" to="#">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link title="battery world mpm" to="#">
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="mailto:batteryworldmpm@gmail.com">
                      <i
                        className="fal fa-envelope"
                        style={{ paddingTop: 4 }}
                      />{" "}
                      batteryworldmpm@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:9495566067">
                      <i className="fal fa-phone" style={{ paddingTop: 4 }} />
                      +91 9495566067
                    </a>
                  </li>
                  <li>
                    <a href="tel:6235566067">
                      <i className="fal fa-phone" style={{ paddingTop: 4 }} />
                      +91 6235566067
                    </a>
                  </li>
                  <li>
                    <Link title="battery world mpm" to="#">
                      <i
                        className="fal fa-map-marker-alt"
                        style={{ paddingTop: 4 }}
                      />{" "}
                      Chattipparamba, Kottakkal Road, Kerala 676504
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
              >
                <div className="apps-download-btn">
                  {/* <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-apple" /> Download for iOS
                      </a>
                    </li>
                    <li>
                      <a className="item-2" href="#">
                        <i className="fab fa-google-play" /> Download for
                        Android
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="copyright-text">
                  <p>
                    Copyright © {new Date().getFullYear()} Battery World. All
                    rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeThree;
