import React from "react";

function ProjectHomeOne() {
  return (
    <>
      <section className="appie-project-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="appie-project-box wow animated slideInUp"
                data-wow-duration="1000ms"
                data-wow-delay="0ms"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="appie-project-content">
                      <h3 className="title">
                        Start your day with Battery World.
                      </h3>
                      <p>We'll keep you updated on the latest technologies</p>
                      <form action="#">
                        <div className="input-box mt-30">
                          <input type="text" placeholder="Your email" />
                          <button type="button">Subscribe</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="appie-project-thumb">
                  <img src="assets/images/project-bg.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProjectHomeOne;
