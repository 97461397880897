import PropTypes from "prop-types";
import LogoSm from "../assets/images/logo/LogoSm.png";
import { useMetaTags } from "react-metatags-hook";
import { Helmet } from "react-helmet";


const MetaData = ({ title, content, description, canonicalLink, ogImage }) => {
  const FRONT_END_URL = "https://batteryworldmpm.in/";

  useMetaTags({
    title: `${title} | Battery World`,
    canonical: canonicalLink
      ? `${FRONT_END_URL}${canonicalLink}`
      : FRONT_END_URL,
    description,
    image: ogImage || LogoSm,
    type: "services",
    og: {
      title: content,
      description,
    },
  });

  return (
    <Helmet>
      <title>{`${title} | Battery World`}</title>
      <link rel="canonical" href={canonicalLink ? canonicalLink : "/"} />
      <link rel="icon" href={LogoSm} />
      <meta property="og:title" content={content} />
      <meta property="og:type" content="services" />
      <meta property="og:description" content={description} />
      <meta
        property="og:url"
        content={`${FRONT_END_URL}${canonicalLink && canonicalLink}`}
      />
      <meta property="og:image" content={ogImage || LogoSm} />
    </Helmet>

  );
};

export default MetaData;

// const MetaData = ({ title, content, description, canonicalLink, ogImage }) => {
//   const FRONT_END_URL = "https://batteryworldmpm.in/";

//   return (
//     <MetaTags>
//       <title>{`${title} | Battery World`}</title>
//       <link rel="canonical" href={canonicalLink ? canonicalLink : "/"} />
//       <link rel="icon" href={LogoSm} />
//       <meta property="og:title" content={content} />
//       <meta property="og:description" content={description} />
//       <meta name="description" content={description} />
//       <meta property="og:type" content="services" />
//       <meta
//         property="og:url"
//         content={`${FRONT_END_URL}${canonicalLink && canonicalLink}`}
//       />
//       <meta property="og:image" content={ogImage || LogoSm} />
//     </MetaTags>
//   );
// };

// export default MetaData;


MetaData.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  keyword: PropTypes.string,
  description: PropTypes.string,
  canonicalLink: PropTypes.string,
  ogImage: PropTypes.string,
};
