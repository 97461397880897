import React from "react";
import BackToTop from "../components/BackToTop";
import AboutHomeThree from "../components/Home/AboutHomeThree";
import DownloadHomeThree from "../components/Home/DownloadHomeThree";
import FooterHomeThree from "../components/Home/FooterHomeThree";
import FunFactHomeThree from "../components/Home/FunFactHomeThree";
import HeaderHomeThree from "../components/Home/HeaderHomeThree";
import HeroHomeThree from "../components/Home/HeroHomeThree";
import ProjectHomeThree from "../components/Home/ProjectHomeThree";
import ServicesHomeThree from "../components/Home/ServicesHomeThree";
import ShowCaseHomeThree from "../components/Home/ShowCaseHomeThree";
import Drawer from "../components/Mobile/Drawer";
import useToggle from "../Hooks/useToggle";
import MetaData from "../Hooks/Meta-tag";
import SEOData, { SEODataTwo } from "../components/SEO/SEOData";

function Home() {
  const [drawer, drawerAction] = useToggle(false);
  return (
    <>
      <MetaData
        content={"Battery World"}
        title={"PREMIUM INVERTER,BATTERY,SOLAR SALES SERVICE"}
        description={
          "trusted source for batteries, solar systems, and inverters in Chattiparamba. Explore our wide range of products and services to power your life efficiently and affordably"
        }
        canonicalLink="https://batteryworldmpm.in/home"
      />
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HeaderHomeThree action={drawerAction.toggle} />
      <HeroHomeThree />
      <ServicesHomeThree />
      <FunFactHomeThree />
      <AboutHomeThree />
      <ShowCaseHomeThree />
      <SEOData />
      <DownloadHomeThree />
      <ProjectHomeThree />
      <FooterHomeThree />

      <BackToTop className="back-to-top-3" />
      <SEODataTwo />
    </>
  );
}

export default Home;
