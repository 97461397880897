import React from "react";
import { Link } from "react-router-dom";
import { service_banner } from "../../assets/images";

function DetailsService() {
  return (
    <>
      <section className="appie-service-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-details-sidebar mr-50">
                <div className="service-category-widget">
                  <ul>
                    <li>
                      <i className="fal fa-home"></i>Fast Installation
                    </li>
                    <li>
                      <i className="fal fa-car"></i>Free delivery
                    </li>
                    <li>
                      <i className="fal fa-industry"></i>Build our own inverter
                    </li>
                    <li>
                      <i className="fal fa-bookmark"></i>Best service
                    </li>
                    <li>
                      <i className="fal fa-globe"></i>
                      batteryworldmpm@gmail.com
                    </li>
                    <li>
                      <i className="fal fa-phone"></i>
                      +91 9495566067
                    </li>
                  </ul>
                </div>
                <div className="service-download-widget">
                  <Link to="#" title="new battery world mpm">
                    <i className="fal fa-download"></i>
                    <span>Download Price details</span>
                  </Link>
                </div>
                <div className="service-download-widget">
                  <Link to="#" title="new battery world mpm">
                    <i className="fal fa-file-pdf"></i>
                    <span>View Terms and Conditions</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details-content">
                <div className="thumb">
                  <img src={service_banner} alt="" />
                </div>
                <div className="content">
                  <h3 className="title">What we're Building</h3>
                  <p>
                    Solar systems are made up of solar panels (modules), a
                    mounting system, and a solar inverter with computerised
                    controller. Solar panels produce DC electricity from
                    sunlight. Then the inverter converts the generated
                    electricity into AC, so that it can be used in the
                    household. The computerised controller manages the solar
                    system and ensures optimal performance. If you want battery
                    backup system or an off-the-grid solar system, a battery is
                    required.
                  </p>
                  <p>
                    The most common location for the installation of solar PV
                    panels is the roof. Most roofs typically have the desired
                    specifications for the installation, so that panels get the
                    maximum sunlight.
                  </p>
                  <p>
                    Nevertheless, if installation on the roof is not applicable
                    or desired, the solar panels could also be mounted on the
                    ground. You just need to make sure that there are no objects
                    blocking access to the sun.
                  </p>
                  <span>
                    The following steps explain solar panel installation on a
                    roof:
                  </span>
                  <h6 className="mb-1 mt-3">1. Set Up Scaffolding</h6>
                  <p>
                    Firstly, you have to erect scaffolding to ensure safety
                    during the whole installation process when being on the
                    roof.
                  </p>
                  <h6 className="mb-1 mt-3">2. Install Solar Panel Mounts</h6>
                  <p>
                    Then, the solar panel mounting system has to be set up. This
                    will support the base of the solar panels. The whole
                    mounting structure must be tilted and have an angle between
                    18 to 36 degrees to have maximum sunlight exposure.
                  </p>
                  <h6 className="mb-1 mt-3">3. Install the Solar Panels</h6>
                  <p>
                    When the mounts are set up, the solar panel itself has to be
                    installed on the mounting structure. Make sure to tighten up
                    all the bolts and nuts so that it stays stable.
                  </p>
                  <h6 className="mb-1 mt-3">4. Wire the Solar Panels</h6>
                  <p>
                    The next step in the installation process is to install the
                    electrical wiring. In most cases, MC4 connectors are used
                    because they are suited for all types of solar panels. Make
                    sure to shut off the household’s electricity supply during
                    the wiring installation.
                  </p>
                  <h6 className="mb-1 mt-3">5. Install Solar Inverter</h6>
                  <p>
                    After that, the solar inverter must be connected to the
                    system. It is typically installed near the main panel and it
                    could be both indoors and outdoors. Inverters are more
                    efficient if kept in a cooler place.
                  </p>
                  <p>
                    If the inverter is outdoors, it should be kept out from the
                    afternoon sun. If it is installed indoors, the garage or
                    utility room are usually the best places, since they stay
                    cool for most of the year and have ventilation.
                  </p>
                  <h6 className="mb-1 mt-3">
                    6. Bond Solar Inverter and Solar Battery
                  </h6>
                  <p>
                    Thereafter, the solar inverter has to be connected to the
                    solar battery. The solar battery storage can save you from
                    worrying about the lack of usable energy during cloudy
                    times, it can also lower the solar battery storage system
                    costs during installation.
                  </p>
                  <h6 className="mb-1 mt-3">
                    7. Connect the Inverter to the Consumer Unit
                  </h6>
                  <p>
                    The inverter should be connected to the consumer unit to
                    generate electricity. A generation meter should also be
                    connected to monitor the amount of electricity the solar
                    panels actually produce. You can use your computer or other
                    device to check your solar system’s performance. For
                    example, you can check how much electricity you generate at
                    different times and decide what time is suitable for using
                    your washing machine or other utilities.
                  </p>
                  <h6 className="mb-1 mt-3">8. Start and Test Solar Panels</h6>
                  <p>
                    The final step is to switch the power on and test the newly
                    installed solar panel system. After that, the solar panel
                    installation process is completed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsService;
